import { Button, Table, TablePaginationConfig, Tooltip, Typography } from 'antd/lib'
import { SorterResult } from 'antd/lib/table/interface'
import Link from 'antd/lib/typography/Link'
import Title from 'antd/lib/typography/Title'
import {
  DeployActionEnum,
  DeployStatusEnum,
  DeviceStatusEnum,
  DevicesSortOptions,
  SoftwareAvailableDTO,
  SoftwareNameEnum,
  SortDirectionOptions,
  DeviceDTO,
  CameraDetailsDTO,
} from 'api/api_code'
import { DeviceDetailsEndpoints, SoftwaresEndpoints } from 'api/axios/axiosAPIGroups'
import clsx from 'clsx'
import { Icon } from 'components/UI/Icon/Icon'
import { IconNames } from 'components/UI/Icon/icons'
import { ConfirmationModal } from 'components/UI/Modals/ConfirmationModal'
import { IconType, NotificationModal } from 'components/UI/Modals/NotificationModal'
import { PAIRING_STEPS, pairingSteps } from 'core/pairing/pairing'
import { getFromSessionStorage, setToSessionStorage } from 'helpers/storageUtils'
import { useHTTPRequestHandler } from 'hooks/useHTTPRequestHandler'
import { usePairingContext } from 'hooks/usePairingContext'
import { useSession } from 'hooks/useSession'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import getFirmwareStatus from '../helpers/getFirmwareStatus'
import useCameraFirmwareActions from '../hooks/useCameraFirmwareStatus'
import { usePairing } from '../pairing/components/deploy/hooks/usePairing'
import styles from './CameraList.module.css'
import { CameraStatus } from './CameraStatus'
import FirmwareField, { FirmwareStatus } from './FirmwareField'
import { useCameraStore } from 'stores/camera.store'
import { useLoading } from 'hooks/useLoading'
import { getCameraLicense } from 'components/camera/helpers/getCameraLicense'

const { Text } = Typography

function CamerasList() {
  const { t } = useTranslation('cameras')
  const router = useRouter()
  const [openModal, setOpenModal] = useState(false)
  const [data, setData] = useState<DeviceDTO[]>([])
  const [total, setTotal] = useState(0)
  const [softwareAvailable, setSoftwareAvailable] = useState<SoftwareAvailableDTO>({
    name: '',
    version: { version_number: '' },
  })
  const {
    currentStep,
    setCurrentStep,
    setPairingModalState,
    pairedDeviceUUID,
    softwareInstallationState,
    setOpenLicensingModal,
  } = usePairingContext()
  const { requestHandler } = useHTTPRequestHandler()
  const { deploySoftwareInstallation } = usePairing()
  const { firmwareActionInProgress, cameraFirmwareAction, resetFirmwareActions } =
    useCameraFirmwareActions()
  const { closeWizardSession } = useSession()
  const { setLoading } = useLoading()
  const paginationValues = useRef({
    sortBy: (router.query['sortBy'] as string) || 'name',
    sortDir: (router.query['sortDir'] as string) || 'asc',
    pageSize: +router.query['pageSize'] || 10,
    currentPage: +router.query['page'] || 1,
  })
  const device_uuid = getFromSessionStorage('device_uuid')

  const cameraListCall = (onOkCallback: CallableFunction, autoLoading = true) => {
    requestHandler({
      requestPromise: DeviceDetailsEndpoints.getCamerasListDevicesGet(
        '',
        paginationValues.current.sortBy as DevicesSortOptions,
        paginationValues.current.sortDir as SortDirectionOptions,
        paginationValues.current.currentPage,
        paginationValues.current.pageSize
      ),
      onOkCallback,
      autoLoading,
    })
  }

  useEffect(() => {
    const device_uuid = getFromSessionStorage('device_uuid')
    const session_token = getFromSessionStorage('session_token')
    if (session_token !== null && device_uuid !== null) {
      closeWizardSession(device_uuid)
    }
    sessionStorage.removeItem('device_uuid')
    sessionStorage.removeItem('camera-store')
  }, [])

  const accessToCamDetails = useCallback(
    (device_uuid) => {
      requestHandler({
        requestPromise: DeviceDetailsEndpoints.getCameraDetailDevicesDeviceUuidGet(
          device_uuid as string
        ),
        onOkCallback: (camera: CameraDetailsDTO) => {
          setLoading(false)
          useCameraStore.setState(camera)
        },
        autoLoading: false,
      })
    },
    [device_uuid]
  )

  useEffect(() => {
    if (!router.isReady) return
    cameraListCall((data) => {
      setData(data.items)
      setTotal(data.total)
    })
    requestHandler({
      requestPromise: SoftwaresEndpoints.getSoftwareSoftwaresSoftwareGet(
        SoftwareNameEnum.Automatictv
      ),
      onOkCallback: (data) => {
        setSoftwareAvailable(data)
      },
    })
  }, [paginationValues.current.currentPage, router.isReady])

  const refreshCamList = (autoLoading = true) =>
    cameraListCall((data) => {
      setData(data.items)
      setTotal(data.total)
      resetFirmwareActions()
    }, autoLoading)

  useEffect(() => {
    const intervalTimer = setInterval(() => refreshCamList(false), 60 * 100)
    return () => {
      clearInterval(intervalTimer)
    }
  }, [
    paginationValues.current.pageSize,
    paginationValues.current.currentPage,
    paginationValues.current.sortDir,
    paginationValues.current.sortBy,
  ])

  useEffect(() => {
    paginationValues.current = {
      sortBy: (router.query['sortBy'] as string) || 'name',
      sortDir: (router.query['sortDir'] as string) || 'asc',
      pageSize: +router.query['pageSize'] || 10,
      currentPage: +router.query['page'] || 1,
    }
  }, [router.isReady])

  const setTextStyles = (status: DeviceStatusEnum) =>
    clsx({
      [styles.soft]: status === DeviceStatusEnum.Offline,
      [styles.velvet]: status === DeviceStatusEnum.Recording,
    })

  const columns = useMemo(
    () => [
      {
        title: t('columnNames.name'),
        dataIndex: 'name',
        key: 'name',
        width: '22%',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text, record) => (
          <div className={styles.nameCol}>
            <Text className={setTextStyles(record.status)}>{record.name}</Text>
            <Text style={{ color: 'var(--soft-500)', fontWeight: 400 }}>
              {record.producer?.serial_number}
            </Text>
          </div>
        ),
      },
      {
        title: t('columnNames.organization'),
        dataIndex: 'organization',
        width: '20%',
        key: 'organization',
        render: (_, record) => (
          <Text data-testid="camera_organization">{record.organization?.name}</Text>
        ),
      },
      {
        title: t('columnNames.firmware'),
        dataIndex: 'software_installed',
        key: 'firmware',
        width: '19%',
        render: (_, record) => {
          const { version, status } = getFirmwareStatus({ device: record, softwareAvailable })
          const firmwareAction = {
            [FirmwareStatus.UPGRADE]: (event) => {
              setOpenModal(true)
              pairedDeviceUUID.current = record.uuid
              event.stopPropagation()
            },
            [FirmwareStatus.INSTALL]: (event) => {
              cameraFirmwareAction[FirmwareStatus.INSTALL]({ cameraUUID: record.uuid })
              event.stopPropagation()
            },
          }
          const upgradeInProgress =
            firmwareActionInProgress[record.uuid] && FirmwareStatus.UPGRADING
          return (
            <FirmwareField
              version={version}
              newVersion={softwareAvailable?.version?.version_number}
              action={firmwareAction[status]}
              firmwareStatus={upgradeInProgress || status}
              deviceStatus={record.status}
              translate={t}
              disable={
                record.status === DeviceStatusEnum.Offline ||
                record.status === DeviceStatusEnum.Recording
              }
              actionInProgress={firmwareActionInProgress?.[record.uuid] ?? false}
            />
          )
        },
      },
      {
        title: t('columnNames.status'),
        dataIndex: 'status',
        width: '15%',
        key: 'status',
        render: (_, record) => (
          <CameraStatus camera={record} setOpenLicensingModal={setOpenLicensingModal} />
        ),
      },
      {
        title: t('columnNames.license'),
        key: 'producer',
        width: '24%',
        dataIndex: 'producer',
        render: (text, record) => {
          const caseOffline = record.status === DeviceStatusEnum.Offline
          const caseRecording = record.status === DeviceStatusEnum.Recording
          const caseUpgrading =
            record.software_installing?.find(
              (item) => item.version?.software?.name === SoftwareNameEnum.Automatictv
            )?.action === DeployActionEnum.Upgrade
          const caseInstalling =
            record.software_installing?.find(
              (item) => item.version?.software?.name === SoftwareNameEnum.Automatictv
            )?.action === DeployActionEnum.Install
          const caseNotLicensed = record.status === DeviceStatusEnum.Unlicensed
          const disableCameraActions =
            caseOffline || caseUpgrading || caseInstalling || caseNotLicensed || caseRecording
          return (
            <div className={`${styles.settings} ${setTextStyles(record.status)}`}>
              {getCameraLicense(record.producer?.license_type)}
              <Tooltip
                placement="topRight"
                title={
                  caseOffline
                    ? t('tooltip.offline')
                    : caseUpgrading
                    ? t('tooltip.upgrading')
                    : caseInstalling
                    ? t('tooltip.installing')
                    : caseRecording
                    ? t('tooltip.recording')
                    : t('tooltip.camConfig')
                }
                className={styles['tooltipCameraList-config']}
              >
                <Button
                  type="link"
                  className={`${styles.hiddenButtons} ${
                    disableCameraActions ? styles.disabledSet : styles.available
                  }`}
                  {...(!disableCameraActions && {
                    onClick: (event) => {
                      useCameraStore.setState(record)
                      setToSessionStorage('lens-size', record.lens_size)
                      setToSessionStorage('license-type', record.producer.license_type)
                      event.stopPropagation()
                      router.push({
                        pathname: '/camera/[device_uuid]/settings',
                        query: { device_uuid: record.uuid },
                      })
                    },
                  })}
                >
                  <Icon component={IconNames.SETTINGS} size={24} className={styles.set}></Icon>
                </Button>
              </Tooltip>
            </div>
          )
        },
      },
    ],
    [firmwareActionInProgress, data, softwareAvailable]
  )

  return (
    <div className={styles.wrapperTable}>
      <div className={styles.header}>
        <Title level={3} className={styles.title}>
          {t('title')} <div className={styles.circle}>{total}</div>
        </Title>
        <Button
          type="primary"
          aria-label={t('pairButton')}
          onClick={() => {
            setPairingModalState(true)
          }}
        >
          <Icon component={IconNames.PLUS} size={24} />
          {t('pairButton')}
        </Button>
      </div>
      <NotificationModal
        titleIconType={
          softwareInstallationState === DeployStatusEnum.Succeed ? IconType.OK : IconType.KO
        }
        title={
          softwareInstallationState === DeployStatusEnum.Succeed
            ? t('installationModal.success.title')
            : t('installationModal.error.title')
        }
        body={
          <p>
            {softwareInstallationState === DeployStatusEnum.Succeed ? (
              t('installationModal.success.explanation')
            ) : (
              <Trans
                i18nKey="cameras:installationModal.error.explanation"
                components={[
                  <Link key="mail-support" href="mailto:support@automatic.tv">
                    {t('supportEmail')}
                  </Link>,
                ]}
              />
            )}
          </p>
        }
        open={currentStep?.index === PAIRING_STEPS.DEPLOY_STATUS}
        onOk={() => {
          softwareInstallationState === DeployStatusEnum.Succeed
            ? setCurrentStep((prevStep) => ({
                index: prevStep.index + 1,
                component: null,
                title: null,
                isCompleted() {
                  return true
                },
              }))
            : setCurrentStep(pairingSteps[1])
        }}
        width={438}
        centered
        footer={null}
        closable={true}
        onCancel={() => {
          setCurrentStep(pairingSteps[1])
        }}
        footerBtns={[
          <Button
            key={0}
            type="ghost"
            aria-label="Error"
            onClick={() => {
              softwareInstallationState === DeployStatusEnum.Succeed
                ? setCurrentStep((prevStep) => ({
                    index: prevStep.index + 1,
                    component: null,
                    title: null,
                    isCompleted() {
                      return true
                    },
                  }))
                : deploySoftwareInstallation()
            }}
          >
            {softwareInstallationState === DeployStatusEnum.Succeed
              ? t('installationModal.success.confirm')
              : t('installationModal.error.confirm')}
          </Button>,
        ]}
      />
      <Table
        className={styles.table}
        columns={columns}
        sortDirections={['ascend', 'descend']}
        onChange={(
          pagination: TablePaginationConfig,
          _,
          sorter: SorterResult<any> | SorterResult<any>[]
        ) => {
          const sorterMod = sorter as SorterResult<any>
          const direction =
            sorterMod?.order === 'ascend' || sorterMod?.order === undefined ? 'asc' : 'desc'

          router.push({
            pathname: '/',
            query: {
              page: paginationValues.current.currentPage,
              pageSize: paginationValues.current.pageSize,
              ...(Object.keys(sorterMod).length && {
                sortBy: sorterMod?.field as string,
                sortDir: sorterMod?.field && direction,
              }),
            },
          })
        }}
        dataSource={data}
        rowClassName={styles.tableRow}
        onRow={(record) => {
          return {
            onClick: () => {
              setToSessionStorage('device_uuid', record.uuid)
              accessToCamDetails(record.uuid)
              router.push({
                pathname: '/camera/[device_uuid]/details',
                query: { device_uuid: record.uuid },
              })
            },
          }
        }}
        pagination={{
          position: ['bottomCenter'],
          total: total,
          current: paginationValues.current.currentPage,
          pageSize: paginationValues.current.pageSize,
          onChange: (page, pageSize) =>
            (paginationValues.current = {
              ...paginationValues.current,
              currentPage: page,
              pageSize,
            }),
          showTotal: (total, range) => (
            <span
              style={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                bottom: '48px',
              }}
            >
              {t('pagination1')} {range[0]}-{range[1]} {t('pagination2')} {total}
            </span>
          ),
          showLessItems: true,
          showSizeChanger: false,
        }}
        data-testid="cameras-list"
      />
      <ConfirmationModal
        modalState={openModal}
        confirmTitleText={t('confirmationModal.title')}
        confirmQuestionText={t('confirmationModal.question')}
        explanationText={t('confirmationModal.explanation')}
        confirmGuideText={t('confirmationModal.confirmGuide')}
        cancelText={t('confirmationModal.cancel')}
        confirmText={t('confirmationModal.start')}
        closeCallback={() => {
          setOpenModal(false)
        }}
        confirmCallback={() => {
          cameraFirmwareAction[FirmwareStatus.UPGRADE]({ cameraUUID: pairedDeviceUUID.current })
          setOpenModal(false)
        }}
      />
      {total === 0 && (
        <div className={styles.noData}>
          <img src={'/img/no-data.png'} alt="No cameras" />
          <p className={styles.noDataTitle}>{t('noData.title')}</p>
          <p>{t('noData.desc')}</p>
        </div>
      )}
    </div>
  )
}
export default CamerasList
